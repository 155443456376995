<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Exam categories</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Exam categories
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="createCategory()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add category
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">

              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                    label="Category name"
                    v-model="search.name"
                    outlined
                    dense
                    v-on:keyup.enter="searchCategory()"
                    @input="search.name = $event !== null ? $event : ''"
                    clearable
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="2">
                  <v-select
                    :items="search_types"
                    v-model="search.type"
                    label="Product type"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    v-on:keyup.enter="searchCategory()"
                    clearable
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="2">
                  <v-select
                    :items="search_instruments"
                    v-model="search.has_instruments"
                    label="Family status"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    v-on:keyup.enter="searchCategory()"
                    clearable
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="2">
                  <v-select
                    :items="search_syllabuses"
                    v-model="search.has_syllabuses"
                    label="Syllabus status"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    v-on:keyup.enter="searchCategory()"
                    clearable
                  ></v-select>
                </v-col>


                <v-col class="d-flex" cols="12" sm="2">
                  <v-select
                    :items="search_statuses"
                    v-model="search.status"
                    label="Status"
                    item-text="name"
                    item-value="value"
                    outlined dense
                    v-on:keyup.enter="searchCategory()"
                    clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-btn
                    @click.prevent="searchCategory()"
                    class="btn btn-primary btn-block w-35 float-right"
                    :loading="loading"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>
            <div class="table-responsive">

              <v-skeleton-loader
                type="table-thead"
                v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Category</th>
                  <th class="px-3">Product</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Instruments</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Syllabuses</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Exam type</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Exam option</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Grades</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">SCORES</th>
                  <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>

                <template>
                  <draggable
                    v-model="categories"
                    @change="sort"
                    @start="drag=true"
                    tag="tbody"
                    @end="drag=false"
                  >
                    <tr v-show="categories.length" v-for="category in categories">
                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                        <a @click="editCategory(category.id)"
                          class="text-primary font-weight-bolder text-hover-primary mb-1">
                          <i class="fa fa-sort"></i> {{ category.name }}
                        </a>
                      </td>
                      <td>
                        {{ category.is_online ? 'Online' : 'Practical' }}
                      </td>
                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                        <span class="badge"
                              v-bind:class="{ 'badge-success': category.has_instruments, 'badge-warning': !category.has_instruments }"
                        >{{ category.has_instruments ? 'Enable' : 'Disable' }}</span>
                        <br>
                        <div class="text-secondary">
                          Total: {{category.instrument_count}}
                        </div>
                      </td>

                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                        <span class="badge"
                              v-bind:class="{ 'badge-success': category.has_syllabuses, 'badge-warning': !category.has_syllabuses }"
                        >{{ category.has_syllabuses ? 'Enable' : 'Disable' }}</span>
                        <div class="text-secondary">
                          Total: {{category.syllabus_count}}
                        </div>
                      </td>

                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                        <span class="badge"
                              v-bind:class="{ 'badge-success': category.has_exam_types, 'badge-warning': !category.has_exam_types }"
                        >{{ category.has_exam_types ? 'Enable' : 'Disable' }}</span>
                      </td>

                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                        <span class="badge"
                              v-bind:class="{ 'badge-success': category.has_exam_options, 'badge-warning': !category.has_exam_options }"
                        >{{ category.has_exam_options ? 'Enable' : 'Disable' }}</span>
                      </td>


                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                        <ul v-if="category.applicable_grade_lists">
                          <li  v-for="grade in category.applicable_grade_lists">
                            {{grade.name}}
                          </li>
                        </ul>
                        <ul v-else>
                          NA
                        </ul>  
                      </td>

                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                        <ul v-if="category.score_lists">
                          <li v-for="score in category.score_lists">
                            {{ score.name }}
                          </li>
                        </ul>
                        <ul v-else>
                          NA
                        </ul>  
                      </td>

                      <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                          <span class="badge"
                                v-bind:class="{ 'badge-success': category.is_active, 'badge-danger': !category.is_active }"
                          >{{ category.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-left">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item"  v-if="currentUser.access_type=='federal'">
                                <a class="navi-link" @click="editCategory(category)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteCategory(category)">
                                  <span class="navi-icon">
                                     <i class="fas fa-trash"></i>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="categories.length == 0">
                      <td  class="px-3 text-center" colspan="10">Items not found.</td>
                    </tr>
                  </draggable>
                </template>
              </table>
              <b-pagination
                  v-if="categories.length > 0"
                  class="pull-right mt-7"
                  @input="getAllCategories"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>

            <v-overlay :value="isSorting">
              <v-progress-circular
                indeterminate
                color="dark"
              ></v-progress-circular>
            </v-overlay>

          </div>
        </div>
      </div>
    </div>
   <create-and-update ref="create-and-update" @refresh="getAllCategories"></create-and-update>
  </v-app>
</template>
<script>
import CategoryService from "@/services/product/category/CategoryService";
import GradeService from "@/services/product/grade/GradeService";
import CreateAndUpdate from "./CreateAndUpdate";
import draggable from 'vuedraggable';
import {required, email, requiredIf} from "vuelidate/lib/validators";

const category = new CategoryService();
const grade = new GradeService();

export default {
  components: {
    CreateAndUpdate, draggable
  },
  data() {
    return {
      title: "",
      imgurl: null,
      dialog: false,
      total: null,
      perPage: null,
      loading: false,
      isSorting: false,
      page: null,
      edit: false,
      visibility: [
        {name: 'Visible', value: 'visible'},
        {name: 'Not Visible', value: 'not_visible'},
      ],

      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },

      search_types: [
        {name: 'Online', value: 1},
        {name: 'Practical', value: 0},
      ],

      search_statuses: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      search_instruments: [
        {name: 'Enabled', value: 1},
        {name: 'Disabled', value: 0},
      ],

      search_syllabuses: [
        {name: 'Enabled', value: 1},
        {name: 'Disabled', value: 0},
      ],

      search: {
        name: '',
        status: '',
        type: '',
        has_instruments: '',
        has_syllabuses: '',
      },
      errors: [],
      availability: [
        {name: 'Availability', value: null},
        {name: 'Available', value: 'available'},
        {name: 'Not Available', value: 'not_available'},
      ],
      exam_types: [
        {name: 'Select Exam Type', value: null},
        {name: 'Online', value: 'online'},
        {name: 'Offline', value: 'offline'},
      ],
      currentUser:{},
      categories: [],
      grades: [],
      category: {
        name:'',
        description:'',
        has_instruments:'',
        has_syllabuses:'',
        has_exam_types:'',
        has_exam_options:'',
        applicable_grades:'',
        is_online:'',
        is_active:1,
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];

    },
    openDialog() {
      this.dialog = true;
    },
    createCategory() {
      this.$refs['create-and-update'].createCategory();
    },

    editCategory(item) {
      this.$refs['create-and-update'].editCategory(item);
    },
    goToInstrument(categoryId) {
      this.$router.push({
        name: "instrumentByCategory",
        params: { categoryId: categoryId }
      });
    },
    sort(){
      this.isSorting = true;
      category
      .sort(this.categories)
      .then(response => {
        this.getAllCategories();
        this.isSorting = false;
        this.$snotify.success('Category Sorted!!');
      })
      .catch(err => {
        this.isSorting = false;
        this.$snotify.error("Oops something went wrong");
      });
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        let formData = this.convertToFormData();
        if (!this.edit) this._create(formData);
        else this.update(formData);
      }
    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.category) {
        if (key === "image" && this.category[key] != null && this.category[key] != undefined) {
          formData.append('image', this.category[key]);
        } else {
          if (this.category[key]) {
            formData.append(key, this.category[key]);
          }
        }
      }
      return formData;
    },
    _create: function (formData) {
      category
          .create(formData)
          .then(response => {
            this.$snotify.success("Category added");
            this.resetForm();
            this.closeDialog();
            this.getAllCategories();
          })
          .catch((err) => {
            this.errors = err.response.data.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update: function (formData) {
      category
          .update(this.category.id, formData)
          .then(response => {
            this.$snotify.success("Customer updated");
            this.closeDialog();
            this.getAllCategories();
          })
          .catch((err) => {
            this.errors = err.response.data.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },

    getAllCategories() {
      this.loading = true;
      category
          .paginate(this.search, this.page)
          .then(response => {
            this.categories = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    searchCategory() {
      this.getAllCategories();
    },
    deleteCategory(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading = true;
            category
                .delete(item.id)
                .then((response) => {
                  this.isLoading = false;
                  this.getAllCategories();
                  this.$snotify.success("Category deleted");
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    }
  },
  mounted() {
    this.getAllCategories();
    this.getCurrentUser();
  },
  computed: {
  }

}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>
